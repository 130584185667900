import { combineReducers, configureStore } from "@reduxjs/toolkit";

const errorReducer = (state = { authError: undefined }, action) => {
	switch (action.type) {
		case "SET_ERROR":
			const temp = {};
			const { payload } = action;

			if (payload.status && payload.status.length) {
				payload.status &&
					payload?.status.forEach((ele) => {
						if (ele.param === "_error") {
							ele.nestedErrors?.forEach((ele1) => {
								const key = ele1.param.split(".").pop();
								temp[key] = ele1.msg;
							});
						} else if (ele.param?.includes("updates.")) {
							const key = ele.param.split(".").slice(1).join(".");
							temp[key] = ele.msg;
						} else {
							temp[ele.path] = ele.msg;
						}
					});
			}

			return { ...state, [`${payload.scope}Error`]: temp };

		default:
			return state;
	}
};

const loadingReducer = (state = { authLoading: false, googleLoading: false }, action) => {
	switch (action.type) {
		case "SET_LOADING":
			return Object.assign({}, state, {
				[`${action.payload.scope}Loading`]: action.payload.status,
			});
		default:
			return state;
	}
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
	switch (action.type) {
		case "SET_TOAST":
			return Object.assign({}, state, {
				[`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
			});
		case "CLEAR_TOAST":
			return { ...state, error: undefined, success: undefined };
		default:
			return state;
	}
};

const authReducer = (
	state = {
		isLoggedIn: -1,
		role: -1,
		isAuthenticated: -1,
	},
	action
) => {
	switch (action.type) {
		case "AUTH_LOAD_SUCCESS":
			return {
				...state,
				isAuthenticated: action?.payload?.isAuthenticated,
				role: action?.payload?.role,
				isLoggedIn: true,
				user: action?.payload,
			};

		case "AUTH_LOAD_FAILURE":
			return {
				...state,
				isAuthenticated: false,
				isLoggedIn: false,
				role: -1,
			};

		case "AUTH_LOGIN_SUCCESS":
			return {
				...state,
				role: action.payload.role,
				isLoggedIn: true,
			};

		case "AUTH_LOGOUT_SUCCESS":
			return {
				isAuthenticated: false,
				isLoggedIn: false,
				user: undefined,
				role: -1,
			};

		case "LOGIN_SUCCESS":
			return {
				isLoggedIn: true,
				user: action.payload,
			};

		case "STATIC_LOGIN_LIST_SUCCESS":
			return {
				...state,
				loginList: action.payload,
			};
		case "LOGOUT_SUCCESS":
			return {
				isLoggedIn: false,
				role: -1,
				isAuthenticated: -1,
			};

		default:
			return state;
	}
};
const creditsReducer = (
	state = {
		list: undefined,
		userCount: 0,
		userDetails: undefined,
		detail: undefined,
	},
	action
) => {
	switch (action.type) {
		case "GET_USER_CREDITS_BALANCE":
			return {
				...state,
				credits: action.payload.credits,
			};
		case "GET_PURCHASED_HISTORY_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				count: action.payload.count,
			};

		case "ADD_CREDITS_PROCESS":
			return {
				...state,
				url: action.payload.url,
			};

		default:
			return state;
	}
};

const photosReducer = (
	state = {
		list: undefined,
		count: 0,
	},
	action
) => {
	switch (action.type) {
		case "PHOTOS_USAGES_HISTORY_SUCCESS":
			return {
				...state,
				list: action.payload.list,
				count: action.payload.count,
			};
		default:
			return state;
	}
};

const settingReducer = (
	state = {
		list: undefined,
		count: 0,
	},
	action
) => {
	switch (action.type) {
		case "CREDITS_PER_IMAGE":
			return {
				...state,
				credit: action.payload.credit,
				photos: action.payload.photos,
			};
		default:
			return state;
	}
};

const store = configureStore({
	reducer: combineReducers({
		auth: authReducer,
		error: errorReducer,
		loading: loadingReducer,
		msg: msgReducer,
		credits: creditsReducer,
		photos: photosReducer,
		setting: settingReducer,
	}),
});

export default store;
