import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { App as AppWrapper } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorFallbackPage, IS_DEV, SiteLoader, alias } from "./helpers/utils";
import { ErrorBoundary } from "react-error-boundary";
import config from "../src/config.json";

// ToastHandler
import ToastHandler from "./helpers/ToastHandler";

// Translation
import "./helpers/i18n";

// CSS
import "./Assets/FONTS/bootstrap-icons/bootstrap-icons.css";
import "./Assets/CSS/custom.css";
import "./Assets/CSS/style.css";

// Auth CSS
import "./Auth/CSS/style.css";

// Component CSS
import "./Components/CSS/style.css";

// View CSS
import "./View/CSS/style.css";
import "./View/CSS/custom.css";

// Swiper Js
import "swiper/css";
import "swiper/css/navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Auth Pages
const AuthIndex = lazy(() => import("./Auth/AuthIndex"));
const Login = lazy(() => import("./Auth/Pages/Login"));
const Logout = lazy(() => import("./Auth/Pages/Logout"));
const SignUp = lazy(() => import("./Auth/Pages/SignUp"));
const ForgotPassword = lazy(() => import("./Auth/Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./Auth/Pages/ResetPassword"));
const VerifyEmail = lazy(() => import("./Auth/Pages/VerifyEmail"));
const HomePage = lazy(() => import("./View/Pages/HomePage"));
const MyProfile = lazy(() => import("./View/Pages/MyProfile"));
const ViewIndex = lazy(() => import("./View/ViewIndex"));

/** Error Page */
const PageNotFound = lazy(() => import("./Components/Pages/Error"));

root.render(
	<AppWrapper>
		<React.StrictMode>
			<Provider store={store}>
				{/* Remove basename for domain other than "dev.3rddigital.com" */}
				<BrowserRouter basename={config?.IS_DEV !== 2 ? alias : undefined}>
					{/* live */}
					{/* <BrowserRouter> */}

					<Suspense fallback={<SiteLoader />}>
						<ErrorBoundary FallbackComponent={ErrorFallbackPage}>
							{/* Route List */}
							<Routes>
								{/* Auth */}
								<Route path="/auth" element={<AuthIndex />}>
									<Route path="login" element={<Login />} />
									{/* <Route path="logout" element={<Logout />} /> */}
									<Route path="sign-up" element={<SignUp />} />
									<Route path="forgot-password" element={<ForgotPassword />} />
									<Route path="reset-password/:token" element={<ResetPassword />} />
									<Route path="verify/:token" element={<VerifyEmail />} />
								</Route>
								<Route path="/auth/logout" element={<Logout />} />
								{/* Auth */}
								{/* <Route path="/" element={<ViewIndex />}>
									<Route exact path="" element={<HomePage />} />
								</Route> */}
								{/* View */}
								<Route path="/" element={<HomePage />}>
									<Route path="*" element={<PageNotFound />} />
								</Route>

								{/* my profile */}
								{/* <Route path="/my-profile" element={<MyProfile />}>
									<Route path="*" element={<PageNotFound />} />
								</Route> */}
								<Route path="/my-profile" element={<ViewIndex />}>
									<Route exact path="" element={<MyProfile />} />
								</Route>

								{/* View */}

								<Route path="*" element={<PageNotFound />} />
							</Routes>
							{/* Route List End */}
						</ErrorBoundary>
					</Suspense>
				</BrowserRouter>
				<ToastHandler />
			</Provider>
		</React.StrictMode>
	</AppWrapper>
);
