import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorLogo from "../Assets/Images/Icons/somethingWentWrongError.svg";
import config from "../config.json";
import CryptoJS from "crypto-js";
import imageCompression from "browser-image-compression";
/** Environment */
export const alias = "/sparknes";
export const IS_DEV = config?.IS_DEV === 0 ? 0 : config?.IS_DEV || 1; // 0:local | 1:dev | 2:live
export const decodeKey = "hjhjhdhfhghsgdfg545454545454";
/** API URL */
// https://dev.3rddigital.com/sparknes-react/api
// https://dev.3rddigital.com/sparknes-react/google
// https://sparknes.com/backend/api
// https://sparknes.com/backend/google

// export const BASE_URL = `https://dev.3rddigital.com/sparknes-react/api`; //For other domain replace domain name with your domain
// export const BASE_URL_GOOGLE = `https://dev.3rddigital.com/sparknes-react/google`; //For other domain replace domain name with your domain

// export const API_URL = IS_DEV ? `http://localhost:3003/api` : `${BASE_URL}`;

// export const googleLoginURL = `${BASE_URL_GOOGLE}`;

// export const API_ACCESS_TOKEN = IS_DEV
//   ? `U2FsdGVkX19oqMsePY1W2RjS13op6DJgj1Z/Jp1Wy+QLtS7Ww6lhRD+hlSjqhUNcnSwyM/mPVNf1YWL5dkRmLg==`
//   : `U2FsdGVkX19oqMsePY1W2RjS13op6DJgj1Z/Jp1Wy+QLtS7Ww6lhRD+hlSjqhUNcnSwyM/mPVNf1YWL5dkRmLg==`;
// export const API_KEY = "U2FsdGVkX1+nQ5R7P4spJIl0DQh0OJnofN/rD88lEmQlJ+dJo1CY5jHWGpExM98BpfHiWNpO4miZTOvdk6Tp/iiWGWxyzYqhQ3UH90c6bM02Y4og1SK/SytzdCjJJFIE";
export let API_URL;
export let API_KEY;
export let googleLoginURL;
export let frontUrl;
export let launchAppUrl;
export let imageStatic;

switch (IS_DEV) {
	case 0:
		frontUrl = `http://localhost:3001/sparknes`;
		launchAppUrl = "http://localhost:3005";
		API_URL = `http://localhost:3000/api`;
		imageStatic = `http://localhost:3000`;
		API_KEY = "U2FsdGVkX19Se/LB50mMHdB1EVTVxjOdpAaHjDSQDgRayYRBK1aPerK+NRobHI5nUy7zBXZHBu+Tc4wljMUFmQ==";
		googleLoginURL = "http://localhost:3000/google";
		break;
	case 1:
		frontUrl = `https://dev.3rddigital.com/sparknes`;
		launchAppUrl = "https://app.sparknes.com";
		API_URL = `https://dev.3rddigital.com/sparknes-react/api`;
		imageStatic = `https://dev.3rddigital.com/sparknes-react`;
		API_KEY = "U2FsdGVkX19Se/LB50mMHdB1EVTVxjOdpAaHjDSQDgRayYRBK1aPerK+NRobHI5nUy7zBXZHBu+Tc4wljMUFmQ==";
		googleLoginURL = "https://dev.3rddigital.com/sparknes-react/google";
		break;
	case 2:
		frontUrl = `https://sparknes.com`;
		launchAppUrl = "https://app.sparknes.com";
		API_URL = `https://sparknes.com/backend/api`;
		imageStatic = `https://sparknes.com/backend`;
		API_KEY = "U2FsdGVkX19Se/LB50mMHdB1EVTVxjOdpAaHjDSQDgRayYRBK1aPerK+NRobHI5nUy7zBXZHBu+Tc4wljMUFmQ==";
		googleLoginURL = "https://sparknes.com/backend/google";
		break;
	default:
}

/** Common Variables */
export const EMAIL_REGEX = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
// export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
// export const ONLY_ALPHABETS_REGEX = /^[A-Za-z ]+$/;

export const dispatchLoading = (dispatch, scope, status) => dispatch({ type: "SET_LOADING", payload: { scope, status } });
export const dispatchToast = (dispatch, scope, status) => dispatch({ type: "SET_TOAST", payload: { scope, status } });
export const dispatchError = (dispatch, scope, status) => dispatch({ type: "SET_ERROR", payload: { scope, status } });
export const catchHandler = (dispatch, scope) => (err) => {
	console.log("err", err);
	if (err.code === "ERR_NETWORK") dispatchToast(dispatch, "error", "Unable to connect to server");
	// else if (err?.response?.status !== 401) {
	// 	console.log("1234");
	// 	dispatchError(dispatch, scope, err?.response?.data?.error);
	// }
	else dispatchToast(dispatch, "error", err?.response?.data?.message);
	dispatchLoading(dispatch, scope, false);
};
export const elseHandler = (dispatch, scope, data) => {
	dispatchToast(dispatch, "error", data?.msg);
	dispatchError(dispatch, scope, data?.error);
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	// Use Navigate
	const navigate = useNavigate();

	// Use Translation
	const { t } = useTranslation();

	// Use location
	const { pathname } = useLocation();

	// Use Ref
	const originalPathname = useRef(pathname);

	// Goto previous Page
	const goToBack = () => {
		navigate(-1);
	};

	// Use Effect
	useEffect(() => {
		if (pathname !== originalPathname.current) resetErrorBoundary();
	}, [pathname, resetErrorBoundary]);

	return (
		<div className="text-center w-100 d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
			<div>
				<div className="px-3 mb-4 header-logo">
					<img src={ErrorLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate("/")} />
				</div>
				<h5 className="ff_rg mb-1 f18">{t("error.somethingWrong")}</h5>
				<p className="ff_rg f14">
					{t("error.thereWasProblem")}
					<span className="d-block ff_rg f14 mx-3">{t("error.pleaseRefresh")}</span>
				</p>
				<p className="ff_rg f14 mt-3 text-secondary">
					{t("error.somethingNot")} ?{" "}
					<span className="ff_md f14 text-dark">
						{t("error.contact")} :{" "}
						<Link className="ff_md f14 text-dark" to="mailto: support@companyname.com">
							support@companyname.com
						</Link>{" "}
					</span>
				</p>
				<button className="btn btn-primary text-uppercase fs-6 px-3 txt-white mt-3" onClick={() => goToBack()}>
					{t("error.goBack")}
				</button>
			</div>
		</div>
	);
};
/** Error Handling Functions Ends */

export function RegrexForAlphabet(e, setState) {
	return e.target.value.match("^[a-zA-Z ]*$") !== null ? setState(e.target.value) : e.preventDefault();
}

export const blockInvalidWhiteSpace = (e) => [" "].includes(e.key) && e.preventDefault();

/** Loader */
export const SiteLoader = ({ text }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	if (pathname === "/") {
		return null;
	}
	return (
		<div
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				textAlign: "center",
				width: "calc(100% - 1rem)",
			}}
		>
			<div className="Loader">
				<div className="d-block">
					<div className="spinner-border" role="status"></div>
				</div>
			</div>
			<p style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>{text !== undefined ? text : t("pleaseWait")}</p>
		</div>
	);
};

/**encrypt-decrypt */
// export function decrypt(text) {
// 	try {
// 		let secretKey = decodeKey;
// 		const keyUtf = CryptoJS.enc.Utf8.parse(secretKey);
// 		const iv = CryptoJS.enc.Utf8.parse("678025308de70905");
// 		if (text) {
// 			const encrypted = CryptoJS.AES.encrypt(text, keyUtf, {
// 				iv: iv,
// 				padding: CryptoJS.pad.Pkcs7,
// 				mode: CryptoJS.mode.CBC,
// 			});
// 			const encryptedText = encrypted.toString();
// 			return encryptedText;
// 		}
// 	} catch (error) {
// 		console.error("Error encrypting text:", error);
// 		throw error;
// 	}
// }

export function decrypt(text) {
	try {
		// var ciphertext = CryptoJS.AES.encrypt(text, 'hello').toString();
		var bytes = CryptoJS.AES.decrypt(text, "hello");
		return bytes.toString(CryptoJS.enc.Utf8);
	} catch (error) {
		console.error("Error decrypting text:", error);
		throw error;
	}
}
/** Function to compress image before API call */
export const imageCompressorFn = async (file, setState) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	};
	try {
		const compFile = await imageCompression(file, options);
		setState(compFile);
	} catch (error) {
		setState(file);
	}
};
